import { sendMessage } from 'helpers/dwh';

export const gaLinkEvent = (GAEventName) => {
    window?.dataLayer?.push({ event: GAEventName });
};

const navigateToLink = (path, target) => {
    if (target === '_blank') {
        window.open(path);
    } else {
        window.location.href = path;
    }
};

export const clickToLink = (e, GAEventName) => {
    e.preventDefault();

    const eventName = e.target.dataset.ubName;
    const path = e.target.href;
    const { target } = e.target;

    gaLinkEvent(GAEventName);
    sendMessage('click', null, eventName, navigateToLink(path, target));
};
