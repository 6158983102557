// libraries
import React from 'react';
import styled, { css } from 'styled-components';
// components
import { Container, BREAKPOINT } from 'components/UI/Grid';
import { Button } from 'components/UI/Button';
// helpers
import { clickToLink } from 'helpers/analytics';
// hooks
import { useScrollLowerThan } from 'hooks/useScrollLowerThan';

export const CallToAction = ({ isPromoExists }) => {
    const minHeight = 400;
    const isVisible = useScrollLowerThan(minHeight);

    return (
        <StyledCallToAction isVisible={isPromoExists && isVisible}>
            <StyledActionButton
                as="a"
                href="https://plazo-mx.onelink.me/wYgr/bo21t2hr"
                data-ub-name="button-fixed"
                onClick={(e) => clickToLink(e, 'click-download-app')}
                dangerouslySetInnerHTML={{
                    __html: 'Solicita tu tarjeta',
                }}
            />
        </StyledCallToAction>
    );
};

// NOTE: CallToAction → CTA
const StylesHiddenCTA = css`
    opacity: 0;
    pointer-events: none;
`;

const StylesVisibleCTA = css`
    opacity: 1;
    pointer-events: auto;
`;

const StyledCallToAction = styled(Container)`
    display: flex;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
    transition: opacity 0.5s ease;

    ${({ isVisible }) => (isVisible ? StylesVisibleCTA : StylesHiddenCTA)};

    ${BREAKPOINT.sm} {
        justify-content: flex-end;
    }

    ${BREAKPOINT.lg} {
        display: none;
    }
`;

const StyledActionButton = styled(Button)`
    box-shadow: 0 16px 16px -8px rgba(11 65 65 / 12%);

    &:not(:link) {
        filter: grayscale(100%);
    }
`;
