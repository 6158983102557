// libraries
import { createGlobalStyle } from 'styled-components';
// constants
import { COLORS } from 'settings';

const styled = { createGlobalStyle };

export const GlobalStyles = styled.createGlobalStyle`
    *,
    *::before,
    *::after {
        box-sizing: border-box;
    }

    body {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        line-height: 1.2;
        color: inherit; // paste you default color
        &.hidden {
            overflow: hidden;
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    button {
        cursor: pointer;
    }

    .grecaptcha-badge {
        visibility: hidden;
    }

    a {
        color: ${COLORS.secondaryDark};

        &.active,
        &:hover {
            color: ${COLORS.secondaryDark};
        }

        &:focus {
            color: ${COLORS.primary};
        }
    }
`;
