// libraries
import { useStaticQuery, graphql } from 'gatsby';

export const useFooterOptions = () => {
    const data = useStaticQuery(graphql`
        {
            wp {
                acfOptionsFooter {
                    footer {
                        footerText {
                            title
                            text
                        }

                        footerLink {
                            text
                            link
                        }
                    }
                }
            }
        }
    `);

    return data.wp.acfOptionsFooter;
};
