import { useEffect, useState } from 'react';

export const useScrollLowerThan = (height) => {
    const [isLower, setIsLower] = useState(false);

    useEffect(() => {
        const updateScrollState = () => {
            setIsLower(window.scrollY > height);
        };

        window.addEventListener('scroll', updateScrollState);
        updateScrollState();
        return () => {
            window.removeEventListener('scroll', updateScrollState);
        };
    }, []);

    return isLower;
};
