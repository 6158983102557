// libraries
import React from 'react';
import styled, { css } from 'styled-components';
// components
import { Item as MenuLink } from 'components/layouts/Header/Menu/Item';
// constants
import { COLORS } from 'settings';
import { BREAKPOINT } from 'components/UI/Grid';
// hooks
import { useHeaderMenu } from 'hooks/use-header-menu';

export const Menu = ({ isMenuOpen, isFixed, handleClick }) => {
    const items = useHeaderMenu();

    return (
        <StyledMenu isMenuOpen={isMenuOpen} isFixed={isFixed}>
            {items &&
                items.map((item) => (
                    <StyledMenuItem key={item.databaseId}>
                        <MenuLink url={item.url} label={item.label} isMenuOpen={isMenuOpen} handleClick={handleClick} />
                    </StyledMenuItem>
                ))}
        </StyledMenu>
    );
};

const StyledMenu = styled.ul`
    display: none;
    margin: 0;
    padding: 12px 16px;
    list-style-type: none;
    background: ${COLORS.bgGrey};

    ${BREAKPOINT.md} {
        display: flex;
        padding: 12px 0;
    }

    ${({ isMenuOpen }) => {
        if (isMenuOpen) {
            return css`
                position: fixed;
                top: 64px;
                left: 0;
                display: flex;
                flex-direction: column;
                width: 100vw;
                overflow-y: auto;

                ${BREAKPOINT.md} {
                    position: static;
                    flex-direction: row;
                    width: auto;
                }
            `;
        }
    }}

    ${({ isFixed }) => {
        if (isFixed) {
            return css`
                background: ${COLORS.white};
            `;
        }
    }}
`;

const StyledMenuItem = styled.li`
    line-height: 2;

    &:not(:last-child) {
        ${BREAKPOINT.md} {
            margin-right: 50px;
        }
    }
`;
