// libraries
import React from 'react';
import styled, { css } from 'styled-components';

export const Burger = ({ isMenuOpen, handleClick }) => {
    return (
        <StyledBurger
            aria-label={isMenuOpen ? 'Open menu' : 'Close menu'}
            isMenuOpen={isMenuOpen}
            onClick={handleClick}
        >
            <StyledBurgerLine />
            <StyledBurgerLine />
            <StyledBurgerLine />
        </StyledBurger>
    );
};

const StyledBurgerLine = styled.span`
    position: relative;
    width: 100%;
    height: 2px;
    margin-bottom: 5px;
    background: url('/icons/burgerLine.svg');
    background-size: cover;

    &:last-child {
        margin-bottom: 0;
    }

    ${({ isMenuOpen }) => {
        if (isMenuOpen) {
            return css`
                ${StyledBurgerLine}:nth-child(1) {
                    top: 5px;
                    transform: rotate(45deg);
                }
                ${StyledBurgerLine}:nth-child(2) {
                    display: none;
                }
                ${StyledBurgerLine}:nth-child(3) {
                    bottom: 3px;
                    transform: rotate(-45deg);
                }
            `;
        }
    }}
`;

const StyledBurger = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
    padding: 0;
    margin-right: 6px;
    background: transparent;
    border: none;
    cursor: pointer;

    ${({ isMenuOpen }) => {
        if (isMenuOpen) {
            return css`
                ${StyledBurgerLine}:nth-child(1) {
                    top: 5px;
                    transform: rotate(45deg);
                }
                ${StyledBurgerLine}:nth-child(2) {
                    display: none;
                }
                ${StyledBurgerLine}:nth-child(3) {
                    bottom: 2px;
                    transform: rotate(-45deg);
                }
            `;
        }
    }}
`;
