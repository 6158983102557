// libraries
import React from 'react';
import styled from 'styled-components';
import { CustomLink } from 'components/UI/CustomLink';
// components
import { Container, BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'settings';
// hooks
import { useFooterOptions } from 'hooks/use-footer-options';

export const Footer = () => {
    const data = useFooterOptions();

    return (
        <StyledFooter>
            <StyledFooterTop>
                <StyledContainer>
                    <StyledLinks>
                        {data.footer.footerLink
                            ? data.footer.footerLink.map((item, index) => (
                                  <li key={index}>
                                      <CustomLink item={item} />
                                  </li>
                              ))
                            : null}
                    </StyledLinks>
                    <StyledLogos>
                        <img src="/icons/main-logo-white.svg" alt="Plazo" />
                        <img src="/icons/logo-idfinance.svg" alt="IDFINANCE" />
                    </StyledLogos>
                </StyledContainer>
            </StyledFooterTop>
            <StyledContainer>
                {data.footer.footerText
                    ? data.footer.footerText.map((item, index) => (
                          <StyledFooterMiddleContent key={index}>
                              <span>{item.title}</span>
                              <br />
                              <span>{item.text}</span>
                          </StyledFooterMiddleContent>
                      ))
                    : null}
            </StyledContainer>
            <StyledFooterBottom>
                <StyledContainer>
                    <StyledFooterCopyright
                        dangerouslySetInnerHTML={{
                            __html: `©${new Date().getFullYear()} plazo.com.mx`,
                        }}
                    />
                </StyledContainer>
            </StyledFooterBottom>
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    padding-bottom: 70px;
    color: ${COLORS.white};
    background: ${COLORS.secondary};

    ${BREAKPOINT.md} {
        padding-bottom: 0px;
    }
`;

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;

    ${BREAKPOINT.md} {
        flex-wrap: nowrap;
    }
`;

const StyledFooterTop = styled.div`
    padding-bottom: 40px;

    ${BREAKPOINT.md} {
        padding-bottom: 0;
    }

    ${BREAKPOINT.xxl} {
        padding: 80px 0;
    }
`;

const StyledFooterMiddleContent = styled.div`
    margin-bottom: 40px;
    line-height: 1.45;
    color: ${COLORS.lightGreyText};
    font-size: 11px;
    text-align: center;

    ${BREAKPOINT.md} {
        text-align: left;

        &:first-of-type {
            padding-right: 80px;
        }
    }

    ${BREAKPOINT.lg} {
        font-size: 14px;
        line-height: 1.57;
    }
`;

const StyledFooterBottom = styled.div`
    padding: 40px 0 30px;
    border-top: 1px solid;
    color: ${COLORS.lightGreyText};
    font-size: 11px;
`;

const StyledFooterCopyright = styled.span`
    margin: 0 auto;
`;

const StyledLinks = styled.ul`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px 0;
    margin: 0 auto;
    list-style: none;

    ${BREAKPOINT.md} {
        width: 40%;
        margin: initial;
    }

    ${BREAKPOINT.xxl} {
        width: initial;
        padding: 0;
        row-gap: 12px;
    }

    li {
        width: 100%;
        text-align: center;
        margin-bottom: 12px;

        ${BREAKPOINT.md} {
            text-align: left;
        }

        ${BREAKPOINT.xxl} {
            width: initial;
            text-align: initial;
            margin-bottom: 0;
            margin-right: 12px;
        }

        ${BREAKPOINT.xl} {
            margin-right: 40px;
        }
    }

    a {
        color: ${COLORS.white};
        text-decoration: none;
        font-size: 14px;

        &.active,
        &:hover {
            color: ${COLORS.primary};
        }

        &:focus {
            color: ${COLORS.secondaryDark};
        }
    }
`;

const StyledLogos = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    margin: 0 auto;

    img:first-of-type {
        width: 28%;

        ${BREAKPOINT.md} {
            width: 40%;
        }
    }

    img:last-of-type {
        width: 48%;

        ${BREAKPOINT.md} {
            width: 80%;
        }
    }

    ${BREAKPOINT.md} {
        margin: initial;
        flex-wrap: nowrap;

        img {
            width: initial;
            margin-left: 40px;
        }
    }
`;
