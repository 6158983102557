// libraries
import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// constants
import { BREAKPOINT } from 'components/UI/Grid';

export const Logo = () => {
    return (
        <StyledLogo to="/">
            <img src="/icons/main-logo.svg" alt="Plazo" />
        </StyledLogo>
    );
};

const StyledLogo = styled(Link)`
    width: 80px;

    ${BREAKPOINT.md} {
        width: initial;

        img {
            max-width: unset;
        }
    }
`;
