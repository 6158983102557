import { getAllUrlParameters } from 'helpers/setCookies';

const s4 = () => {
    const k1 = 1;
    const k2 = 0x10000;
    const radix = 16;
    const substringStart = 1;

    return Math.floor((k1 + Math.random()) * k2)
        .toString(radix)
        .substring(substringStart);
};

const generateGUID = () => {
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

const getCookiesAsObject = () => {
    return document.cookie.split('; ').reduce((acc, cur) => {
        const [key, value] = cur.split('=');

        acc[key] = value;

        return acc;
    }, {});
};

const generateEventId = () => {
    const k1 = 16;
    const k2 = 0;
    const k3 = 3;
    const k4 = 8;
    const radix = 16;

    return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, (char) => {
        // eslint-disable-next-line no-bitwise
        const random = (k1 * Math.random()) | k2;
        // eslint-disable-next-line no-bitwise
        const result = char === 'x' ? random : (k3 & random) | k4;

        return result.toString(radix);
    });
};

export const clearEventNumber = () => {
    if (localStorage.getItem('event_number') !== null) {
        localStorage.removeItem('event_number');
    }
};

export const setMessageID = () => {
    if (localStorage.getItem('message_id') === null) {
        localStorage.setItem('message_id', generateGUID());
    }
};

export const sendMessage = (eventName, value, idElem, callback) => {
    const requestUrl = '/mxcc-stat-collector/api/3/store/';
    const userID = null;
    const registrationID = localStorage.getItem('user_id');
    const message = localStorage.getItem('message_id');
    const [url, queryParams] = window.location.href.split('?');
    let eventNumber = Number(localStorage.getItem('event_number'));
    const currentStep = eventNumber > 0 ? `LANDING_PAGE` : null;

    let extraValue = value;
    if (eventName === 'loaded') {
        extraValue = { isTrusted: true };
    }

    eventNumber++;
    localStorage.setItem('event_number', eventNumber);

    const cont = {
        logger: 'javascript',
        platform: 'javascript',
        timeStamp: Date.now(),
        request: {
            url: `${url}${currentStep || ''}${queryParams ? `?${queryParams}` : ''}`,
            headers: {
                'User-Agent': window.navigator.userAgent,
                Referer: window.document.referrer,
            },
            step: currentStep,
            queryParams: getAllUrlParameters(),
            cookies: getCookiesAsObject(),
        },
        event_id: generateEventId(),
        message,
        extra: {
            event: eventName,
            name: idElem || message,
            value: extraValue || '',
            id: idElem || message,
        },
        user: {},
    };

    if (registrationID) {
        cont.registrationId = registrationID;
    }

    if (userID) {
        cont.user.id = userID;
    }

    fetch(requestUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify(cont),
    }).then((response) => {
        if (callback) {
            callback();
        }
        return response;
    });
};
