// librairies
import React from 'react';
import { Script } from 'gatsby';
// components
import { Layout } from './src/components/layouts/Layout';
// static
import 'normalize.css';

export const wrapPageElement = ({ element, props }) => {
    const { data, location, pageResources } = props;
    const pageLayouts = data?.page?.layouts?.layouts;
    const isWebview = location.pathname.includes('webview');
    const is404Page = pageResources?.page?.path.includes('404');

    const isPromoExists =
        pageLayouts !== undefined &&
        pageLayouts.some((item) => item.fieldGroupName === 'Page_Layouts_Layouts_PromoBlock');

    return (
        <Layout is404Page={is404Page} pathname={location.pathname} isPromoExists={isPromoExists}>
            {element}
            {!isWebview && (
                <Script src="https://www.google.com/recaptcha/api.js?render=6LfzLCEmAAAAAEzlRUVsgCOHYkDoxbfLx0dautoa" />
            )}
        </Layout>
    );
};
