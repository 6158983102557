export const SETTINGS = {
    URL_MASTER: 'https://plazo-com-mx.frm-qa.idfaws.com/wp',
    WP_PREFIX: '/wp',
};

export const API = {
    SEND_CONTACTS_FORM: '/rest/sendContactsForm.php',
};

export const FORM_MESSAGES = {
    name: {
        required: 'Campo obligatorio',
    },
    email: {
        required: 'Campo obligatorio',
        notValid: 'Por favour introduzca un email válido',
    },
    textarea: {
        required: 'Campo obligatorio',
        min: 'Los comentarios requieren un mínimo de 1 caracteres.',
        max: 'El comentario no debe exceder los 5000 caracteres.',
    },
    error: 'Uno o más campos contienen un error. Por favor compruébalo e inténtalo de nuevo',
    success: 'Tu mensaje ha sido enviada. Nos pondremos en contacto contigo pronto',
};

export const COLORS = {
    primary: 'rgb(158, 220, 21)',
    primaryHover: 'rgb(141, 197, 19)',
    secondary: 'rgb(11, 65, 65)',
    secondaryDark: 'rgb(129, 189, 30)',
    white: 'rgb(255, 255, 255)',
    lightGrey: 'rgb(234, 241, 241)',
    lightGreyText: 'rgba(234, 241, 241, 0.5)',
    bgGrey: 'rgb(240, 244, 244)',
    bgGreyLight: 'rgba(247, 249, 249)',
    backgroundGrey: 'rgba(11, 65, 65, 0.05)',
    textSecondary: 'rgb(60, 103, 103)',
    shadow: 'rgba(11, 65, 65, 0.12)',
    lightBlack: 'rgba(0, 0, 0, 0.3)',
    danger: 'rgb(255, 59, 48)',
    dangerShadow: 'rgba(255, 59, 48, 0.04)',
    grayBorder: 'rgb(206, 216, 216)',
};

export const GRADIENTS = {
    primaryGradient: 'linear-gradient(135deg, #ADE92C 0%, #9FDC15 49.98%, #91CF00 100%)',
    secondaryGradient: 'linear-gradient(135deg, #105C5C 0%, #0B4141 100%), #FFFFFF;',
};
