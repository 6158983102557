// libraries
import { nanoid } from 'nanoid';

export const setUserID = (callback) => {
    const generatedID = nanoid();

    if (localStorage.getItem('user_id') === null) {
        localStorage.setItem('user_id', generatedID);
    }

    if (callback) {
        callback();
    }
};
