// libraries
import styled from 'styled-components';
// constants
import { BREAKPOINT } from '../Grid';
import { COLORS } from 'settings';

export const Button = styled.button`
    display: inline-block;
    width: 100%;
    margin: auto;
    max-width: 343px;
    padding: 15px 32px;
    color: ${COLORS.white};
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    background: ${COLORS.primary};
    border: none;
    border-radius: 16px;
    transition: background 0.3s ease;

    ${BREAKPOINT.sm} {
        max-width: 545px;
    }

    ${BREAKPOINT.lg} {
        max-width: 368px;
    }

    &:hover,
    &:focus {
        background: ${COLORS.primaryHover};
    }
`;
