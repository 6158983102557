// libraries
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// components
import { Container, BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'settings';
// helpers
import { setCookie, getCookie } from 'helpers/setCookies';
// static
import COOKIE from 'assets/images/cookie.png';

export const Cookie = () => {
    const [show, setShow] = useState(false);
    const cookieHours = 1440;

    const setShowPoliticaDeCookies = () => {
        setShow(false);
        setCookie('showPoliticaDeCookies', 'no', cookieHours);
    };

    useEffect(() => {
        if (getCookie('showPoliticaDeCookies') === null) {
            setShow(true);
        }
    }, []);

    return show === true ? (
        <StyledCookie>
            <StyledContainer>
                <StyledImage src={COOKIE} aria-hidden />
                <CookieText
                    dangerouslySetInnerHTML={{
                        __html: 'Al hacer uso de este sitio aceptas que usemos cookies para mejorar tu experiencia',
                    }}
                />
                <CookieButton type="button" onClick={setShowPoliticaDeCookies}>
                    &times;
                </CookieButton>
            </StyledContainer>
        </StyledCookie>
    ) : null;
};

const StyledCookie = styled.div`
    background: ${COLORS.white};
    border-top: 1px solid ${COLORS.bgGrey};
    color: ${COLORS.secondary};
    pointer-events: auto;
`;

const StyledContainer = styled(Container)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledImage = styled.img`
    width: 28px;
    height: 28px;
    margin: 8px 0;
    ${BREAKPOINT.md} {
        font-size: 12px;
        line-height: 1.5;
        margin-left: auto;
    }
    width: 32px;
    height: 32px;
`;

const CookieText = styled.p`
    font-size: 11px;
    line-height: 1.45;
    padding-left: 8px;

    ${BREAKPOINT.md} {
        font-size: 12px;
        line-height: 1.5;
    }
`;

const CookieButton = styled.button`
    font-size: 24px;
    border: none;
    padding-bottom: 3px;
    background: transparent;
    color: ${COLORS.secondary};

    ${BREAKPOINT.md} {
        margin-left: auto;
    }
`;
