// libraries
import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
// components
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'settings';

export const Item = ({ handleClick, url, label, isMenuOpen }) => {
    return (
        <StyledItem
            activeClassName="active"
            to={url}
            dangerouslySetInnerHTML={{ __html: label }}
            onClick={isMenuOpen ? handleClick : null}
        />
    );
};

const StyledItem = styled(Link)`
    text-decoration: none;
    font-size: 16px;
    color: ${COLORS.textSecondary};

    ${BREAKPOINT.lg} {
        font-size: 18px;
    }
`;
