// libraries
import React from 'react';
import { Link } from 'gatsby';

export const CustomLink = ({ item }) => {
    const patternPDF = /.+\.pdf$/;
    if (patternPDF.test(item.link)) {
        return (
            <a href={item.link} target="_blank" download rel="noreferrer">
                {item.text}
            </a>
        );
    }

    return <Link to={item.link}>{item.text}</Link>;
};
