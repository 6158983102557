// libraries
import { useStaticQuery, graphql } from 'gatsby';

export const useHeaderMenu = () => {
    const { menu } = useStaticQuery(
        graphql`
            query MenuHeader {
                menu: wpMenu(databaseId: { eq: 2 }) {
                    items: menuItems {
                        nodes {
                            databaseId
                            url
                            label
                        }
                    }
                }
            }
        `
    );

    return menu.items.nodes;
};
