// libraries
import React, { useEffect } from 'react';
import styled from 'styled-components';
// components
import { GlobalGridVars } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { FixedPanels } from 'components/layouts/FixedPanels';
import { Header } from 'components/layouts/Header';
import { Footer } from '../Footer';
// helpers
import { clearEventNumber, sendMessage, setMessageID } from 'helpers/dwh';
import { setCookiesFromUrlParams } from 'helpers/setCookies';
import { setUserID } from 'helpers/setUserID';

const PageContent = ({ children, isPromoExists, isWebview, is404Page }) => {
    if (is404Page) {
        return (
            <>
                <Header isPromoExists={isPromoExists} />
                <StyledMain>{children}</StyledMain>
                <Footer />
            </>
        );
    }

    if (!isWebview) {
        return (
            <>
                <FixedPanels isPromoExists={isPromoExists} />
                <Header isPromoExists={isPromoExists} />
                <StyledMain>{children}</StyledMain>
                <Footer />
            </>
        );
    }

    return <StyledMain>{children}</StyledMain>;
};

export const Layout = ({ children, isPromoExists, is404Page, pathname }) => {
    const isWebview = pathname.includes('webview');

    useEffect(() => {
        setUserID();
        setCookiesFromUrlParams();
    }, []);

    useEffect(() => {
        clearEventNumber();

        setMessageID();

        sendMessage('open');
        sendMessage('loaded');
    }, [pathname]);

    return (
        <StyledLayout>
            <GlobalStyles />
            <GlobalGridVars />
            <PageContent isPromoExists={isPromoExists} isWebview={isWebview} is404Page={is404Page}>
                {children}
            </PageContent>
        </StyledLayout>
    );
};

const StyledLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const StyledMain = styled.main`
    flex-grow: 1;
`;
