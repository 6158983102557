// libraries
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
// components
import { Logo } from 'components/layouts/Header/Logo';
import { Menu } from 'components/layouts/Header/Menu';
import { Burger } from 'components/layouts/Header/Burger';
import { Container, BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'settings';
// hooks
import { useScrollLowerThan } from 'hooks/useScrollLowerThan';

export const Header = () => {
    const minHeight = 50;
    const isFixed = useScrollLowerThan(minHeight);
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        if (window.matchMedia('(max-width: 768px)').matches) {
            document.body.classList.toggle('hidden', isMenuOpen);
        }
    }, [isMenuOpen]);

    return (
        <>
            <StyledHeader className={isFixed ? `fixed-header` : null} isMenuOpen={isMenuOpen}>
                <StyledContainer>
                    <Logo />
                    <Menu isMenuOpen={isMenuOpen} isFixed={isFixed} handleClick={handleClick} />
                    <StyledBurgerWrapper>
                        <Burger isMenuOpen={isMenuOpen} handleClick={handleClick} />
                    </StyledBurgerWrapper>
                </StyledContainer>
            </StyledHeader>
            {isMenuOpen ? <ShadowWrapper onClick={handleClick} /> : null}
        </>
    );
};

const StyledHeader = styled.header`
    background: ${COLORS.bgGrey};
    z-index: 11;

    &.fixed-header {
        box-shadow: 0px 16px 16px -8px ${COLORS.shadow};
        background: ${COLORS.white};
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;

        ${({ isMenuOpen }) => {
            if (isMenuOpen) {
                return css`
                    background: ${COLORS.white};
                `;
            }
        }}
    }
`;

const StyledContainer = styled(Container)`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${BREAKPOINT.md} {
        height: 80px;
    }
`;

const StyledBurgerWrapper = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${BREAKPOINT.md} {
        display: none;
    }
`;

const ShadowWrapper = styled.div`
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: ${COLORS.lightBlack};

    ${BREAKPOINT.md} {
        display: none;
    }
`;
