// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { Cookie } from 'components/layouts/Cookie';
import { CallToAction } from 'components/layouts/CallToAction';

export const FixedPanels = ({ isPromoExists }) => {
    return (
        <StyledFixedPanels>
            <CallToAction isPromoExists={isPromoExists} />
            <Cookie />
        </StyledFixedPanels>
    );
};

const StyledFixedPanels = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    pointer-events: none;
`;
